<script setup lang="ts">
import { useField, ErrorMessage, useFieldError } from 'vee-validate'
import { VueTelInput } from 'vue-tel-input'
import type { TPhoneObject } from '~/src/types/form'
import type { TGeneral } from '~/src/types/common'
import { createElementFromString } from '~/utils/string'

import 'vue-tel-input/vue-tel-input.css'

type TProps = {
  label?: string
  name?: string
  required?: boolean
  type?: string
  placeholder?: string
  modelValue?: string | number
  rules?: string
  errorMessages?: TGeneral
  withoutLabel?: boolean
  dropdownOptions?: TGeneral
  inputOptions?: TGeneral
  autoFormat?: boolean
}

const props = withDefaults(defineProps<TProps>(), {
  type: 'text',
  label: 'Phone number',
  placeholder: 'Enter phone number',
  rules: '',
  modelValue: '',
  autoFormat: true,
  withoutLabel: false,
  errorMessages: () => ({
    invalid: 'Phone number must be valid',
  }),
})

const emit = defineEmits(['update:modelValue'])

const vti = ref(null)

const rulesWithDialCode = computed(() => {
  return props.rules ? `${props.rules}|min:4` : 'min:4'
})
const error = useFieldError(props.name)
const hasError = computed(() => error.value)

const { setErrors, validate, value } = useField(
  props.name,
  rulesWithDialCode.value,
  {
    label: props.label,
    validateOnValueUpdate: false,
  },
)

const currentPhoneObject = ref<TPhoneObject>(null)

const customDropdownOptions = computed(() => props.dropdownOptions)

const customInputOptions = computed(() => ({
  placeholder: props.placeholder,
  showDialCode: true,
  ...props.inputOptions,
}))

const validateInput = () => {
  if (!currentPhoneObject.value) return validate()

  const { formatted, valid } = currentPhoneObject.value

  if (!valid) {
    if (!formatted) return validate()
    return setErrors(props.errorMessages.invalid)
  }

  emit('update:modelValue', formatted)
  validate()
}

const handleInput = (_: string, phoneObject: TPhoneObject) => {
  const { formatted, valid } = phoneObject
  currentPhoneObject.value = phoneObject

  emit('update:modelValue', formatted)

  if (valid) validate()
}

const beforeinput = (e: InputEvent) => {
  if (!e.data) return
  const newValue = value.value + e.data
  if (newValue === '00') {
    e.preventDefault()
    emit('update:modelValue', '+')
  }
}

const getCountryName = (countryObject: TGeneral) => {
  if (!props.dropdownOptions?.showCountryName || !vti.value.$el) return
  const dropdown = vti.value.$el.querySelector('.vti__dropdown')
  const selection = dropdown.querySelector('.vti__selection')
  if (!selection) return
  const haveCountryName = selection.querySelector('#country-name')
  if (haveCountryName) haveCountryName.remove()

  const countryName = createElementFromString(
    `<span id="country-name" class="text-nowrap line-clamp-1">${countryObject.name}</span>`,
  )

  selection.insertBefore(countryName, selection.firstChild)
}

const preferredCountries = ['gb', 'us', 'ca', 'cn', 'au']
</script>

<template>
  <div>
    <span v-if="!withoutLabel" class="mb-1 block text-xs font-semibold">
      {{ label }} <span v-if="required" class="text-error">*</span>
    </span>
    <VueTelInput
      ref="vti"
      v-model="value"
      :auto-format="autoFormat"
      :preferred-countries="preferredCountries"
      :input-options="customInputOptions"
      :dropdown-options="customDropdownOptions"
      valid-characters-only
      mode="international"
      data-testid="phone-number-input"
      :class="{ error: hasError }"
      @beforeinput="beforeinput"
      @on-input="handleInput"
      @blur="validateInput"
      @country-changed="getCountryName"
    >
      <template #icon-right>
        <slot name="icon-right" />
      </template>
      <template #arrow-icon>
        <slot name="arrow-icon" />
      </template>
    </VueTelInput>

    <ErrorMessage class="block text-xxs text-error" :name="name" />
  </div>
</template>

<style lang="scss">
.vue-tel-input {
  @apply rounded border-s-600 #{!important};

  &:focus-within {
    @apply border-p-500 ring-1 ring-p-500 ring-offset-0 #{!important};
  }

  input {
    @apply px-3 py-1 text-grey-900 shadow-none ring-0 #{!important};
  }

  .vti__dropdown:focus {
    @apply rounded outline-2 outline-offset-0 outline-p-500;
  }

  .vti__dropdown-item strong {
    @apply font-normal #{!important};
  }

  &.error {
    &:focus-within {
      @apply border-error #{!important};
    }

    .vti__dropdown:focus {
      @apply outline-error;
    }
  }
}
</style>
